<template>
    <v-toolbar tile dark color="grey darken-2">
        <v-toolbar-items class="d-flex flex-grow-1 justify-space-around">
            <v-btn class="col-4" text v-for="chip in chips" :key="chip.name"
                :to="`/prestas/${chip.root}`">
                {{ chip.name }}
            </v-btn>
        </v-toolbar-items>
    </v-toolbar>
</template>

<script>
    export default {
        name: "Toolbar",
        data() {
            return {
                chips: [{
                        name: "Logiciel",
                        root: 'web'
                    },
                    {
                        name: "IoT",
                        root: 'iot',
                    },
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>
   ::v-deep .v-toolbar{
        &__content{
            padding: 0px!important;
        }
    }
</style>