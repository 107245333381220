<template>
  <v-container>
    <Toolbar />
    <router-view name="prestas"></router-view>
  </v-container>
</template>

<script>
import Toolbar from "@/components/prestas/Toolbar";

export default {
  name: "Prestas",
  data() {
    return {};
  },
  components: {
    Toolbar,
  },
  beforeMount() {
    window.document.title =
      "Développeur d'Applications à Toulouse - Prestations";
  },
};
</script>

<style lang="scss" scoped>
</style>